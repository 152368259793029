<template>
  <div class="discount-chevron">
    <img src="~/public/img/percent.svg" alt="percent-svg" />
  </div>
</template>

<style scoped lang="scss">
.discount-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 20px;
  background-color: rgba(255, 119, 0, 1);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
