<template>
  <div class="input-number-wrapper">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.input-number-wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  .btn-max {
    border-radius: 12px;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .amount {
    height: 50px;
  }

  .produce {
    padding: 10px 40px;

    @media screen and (max-width: 479px) {
      min-height: 52px;
    }
  }
}
</style>

<style lang="scss">
.input-number-wrapper {
  .el-input-number {
    width: 130px;
    border: 1px solid #fff;
    border-radius: 12px;
    font-family: Eczar, sans-serif;
    font-weight: 400;
    min-width: 130px;
    height: 45px;

    @media screen and (max-width: 479px) {
      min-width: 240px;
      height: 48px;
      min-height: 48px;
    }

    &__decrease,
    &__increase {
      width: 32px;
      color: var(--main-text-color);
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
      top: 0;
      bottom: 0;

      &:hover ~ .el-input:not(.is-disabled) .el-input__wrapper {
        box-shadow: none;
      }

      @media screen and (max-width: 479px) {
        font-size: 32px;
        width: 44px;
        height: 46px;
        -webkit-tap-highlight-color: transparent;
      }
    }

    &__decrease {
      border-radius: 12px 0 0 12px;
      border-right-color: transparent;
      left: 0;

      &:after {
        content: '-';
        font-family: Eczar;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
        color: var(--main-text-color);
      }

      i {
        display: none;
      }
    }

    &__increase {
      border-radius: 0 12px 12px 0;
      border-left-color: transparent;
      right: 0;

      &:after {
        content: '+';
        font-family: Eczar;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
        color: var(--main-text-color);
      }

      i {
        display: none;
      }
    }

    .el-input {
      font-size: 18px;
      line-height: 1;
      background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
      overflow: hidden;
      border-radius: 12px;

      &__inner {
        --el-input-inner-height: 32px;

        font-family: Eczar;
        font-weight: 700;
        font-size: 24px;
        leading-trim: Cap height;
        line-height: 1;
        text-align: center;
        vertical-align: bottom;
        color: var(--main-text-color);
      }

      &__wrapper {
        border-radius: 12px;
        box-shadow: none;
        padding: 6px 11px;
        background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
      }
    }
  }

  .btn-max {
    height: 45px;
    min-height: 45px;

    @media screen and (max-width: 479px) {
      max-width: 150px;
      height: 48px;
      min-height: 48px;
    }
  }
}
</style>
