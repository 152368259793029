<template>
  <div class="blur-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.blur-wrapper {
  z-index: 2;
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: radial-gradient(rgba(4, 15, 20, 0.4), rgba(8, 27, 37, 0.4));
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100%;
  overflow: auto;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.7));
  position: relative;
}
</style>
