<template>
  <el-dialog
    v-model="showModal"
    fullscreen
    class="mobile-popup-content"
    :lock-scroll="true"
    append-to-body
    destroy-on-close
    :class="{ 'ru-locale': $i18n.locale === 'ru' }"
  >
    <MobilePopup @handle-click="toggle">
      <p v-html="$t('infoTooltipSellerModal')"></p>
    </MobilePopup>

    <template #header>
      {{ $t('seller') }}
    </template>
  </el-dialog>
  <el-tooltip :disabled="$device.isMobile" placement="bottom-end" popper-class="popper-default">
    <template #content>
      <p v-html="$t('infoTooltipSellerModal')"></p>
    </template>
    <div class="info-icon" @click="() => $device.isMobile && toggle()" />
  </el-tooltip>
</template>

<script setup lang="ts">
const [showModal, toggle] = useToggle();
provide('showSellerInfoModal', showModal);
</script>

<style scoped lang="scss">
.info-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
  background-image: url('~/public/img/icons/info.svg');

  @media (max-width: 376px) {
    top: 10px;
    left: 10px;
  }

  &:hover {
    opacity: 0.6;
  }
}
</style>
