<template>
  <div class="balance">
    <slot name="prefix" />

    <span v-if="label" class="balance-label">{{ label }}:</span>
    <el-skeleton :loading="isLoading" animated class="skeleton">
      <template #template>
        <el-skeleton-item style="height: 44px" />
      </template>

      <template #default>
        <UIPrice
          :icon-src="tokens[tokenMetaData(blockchain.contracts, tokenAddress).name.contract as Tokens].toString()"
          :value="balanceValue"
          :is-loading="isLoading"
        />
      </template>
    </el-skeleton>
  </div>
</template>

<script setup lang="ts">
import useBalance from '~/composables/useBalance';
import { useEnvs } from '#imports';
import tokens from 'public/img/tokens';
import { Tokens } from '~/utils/constants';

const props = defineProps<{
  tokenAddress: string;
  label?: string;
  isFormatBalance?: boolean;
}>();

const tokenAddressRef = ref(props.tokenAddress);
const { blockchain } = useEnvs();
const { balance, isLoading } = await useBalance(tokenAddressRef);

const balanceValue = computed(() => {
  return props.isFormatBalance ? formatAmount(Math.floor(Number(balance.value))).toString() : balance.value;
});
</script>

<style scoped lang="scss">
.balance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 48px;
  background: rgba(4, 15, 20, 0.85);
  backdrop-filter: blur(2px);
  border: 1px solid var(--blue-4);
  border-radius: 8px;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 15px;
  font-family: Eczar;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  position: relative;

  .balance-label {
    white-space: nowrap;
  }

  .skeleton {
    max-width: 99%;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

.balance::v-deep(.price-value) {
  font-weight: 600;
}
</style>
