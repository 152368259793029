import { TransactionResponse } from 'ethers';
import type { SendParams } from '~/types/Web3Provider';
import { useWalletInfo, useWeb3ModalAccount } from '@web3modal/ethers/vue';
import useSendContractMethod from '~/composables/useSendContractMethod';
import {
  getCancelErrorMessage,
  modifyHistoryHashStatus,
  saveHashToLocalStorage,
  useEnvs,
  useMainStore
} from '#imports';
import { BigNumber } from 'bignumber.js';
import { apiUrls } from '~/utils/constants';
import type { SellerActivityResponse } from '~/types/seller';

interface IUseBuyToken {
  buyToken: (index: number, countTokens: number, price: number, tokenAddress: string) => Promise<void>;
  isButtonLoading: Ref<boolean>;
  showInsufficientFundsDialog: Ref<boolean>;
  showErrorDialog: Ref<boolean>;
  showSuccessDialog: Ref<boolean>;
  linkToTransaction: Ref<string>;
  countTokensPurchased: Ref<number>;
  errorMessage: Ref<string>;
}

export default async (): Promise<IUseBuyToken> => {
  const { t } = useI18n();
  const { address } = useWeb3ModalAccount();
  const { walletInfo } = useWalletInfo();
  const { sendContractMethod } = useSendContractMethod();
  const { blockchain, apiUrl } = useEnvs();
  const store = useMainStore();

  const { allowanceShop } = useTokensReader();
  const { approveSpendUsdt } = useStakingMethods();
  const usdtContract = ref(blockchain.contracts.usdt);
  const { balance } = await useBalance(usdtContract);

  const isButtonLoading = ref(false);
  const showInsufficientFundsDialog = ref(false);
  const showErrorDialog = ref(false);
  const showSuccessDialog = ref(false);
  const errorMessage = ref('');
  const linkToTransaction = ref('');
  const countTokensPurchased = ref(0);

  const sendContractMethodExtended = async (sendParams: SendParams) => {
    const from = address.value;
    return sendContractMethod({ ...sendParams, from });
  };

  const buyTokenContract = (tokenIndex: number, countTokens: string) => {
    return sendContractMethodExtended({
      contract: 'shop',
      address: blockchain.contracts.shop,
      methodName: 'buy',
      methodArguments: [tokenIndex, countTokens]
    });
  };

  const buyToken = async (tokenIndex: number, countTokens: number, price: number, tokenAddress: string) => {
    try {
      if (!balance.value || !address.value) {
        return;
      }

      isButtonLoading.value = true;

      const isEnoughBalance = new BigNumber(balance.value).gte(BigNumber(price));

      if (!isEnoughBalance) {
        showInsufficientFundsDialog.value = true;
        return;
      }

      const allowedCount = await allowanceShop(address.value);

      const shouldCallApprove = new BigNumber(allowedCount).isLessThan(price);

      if (shouldCallApprove) {
        const transactionApprove = await approveSpendUsdt(price);

        if (!transactionApprove?.hash) {
          return;
        }

        saveHashToLocalStorage(t('notificationApprove'), transactionApprove.hash);
        store.updateVersion();

        await transactionApprove?.wait();
      }

      const transactionBuy: TransactionResponse | undefined = await buyTokenContract(tokenIndex, String(countTokens));

      if (!transactionBuy?.hash) {
        return;
      }

      const token = tokenMetaData(blockchain.contracts, tokenAddress);

      saveHashToLocalStorage(
        `${t('coreTokenPurchase')} ${t(token.label, { count: 3 })} (${countTokens})`,
        transactionBuy.hash
      );

      store.updateVersion();

      await transactionBuy.wait();

      useFetch<SellerActivityResponse>(apiUrls.streetMerchant, {
        baseURL: apiUrl,
        method: 'POST',
        body: {
          userAddress: address,
          tokenAddress,
          transactionHash: transactionBuy.hash,
          amount: countTokens
        }
      });

      modifyHistoryHashStatus(transactionBuy?.hash, 'Done');

      store.updateVersion();

      countTokensPurchased.value = countTokens;
      showSuccessDialog.value = true;
      linkToTransaction.value = blockchain.blockScanUrl + '/tx/' + transactionBuy?.hash;
    } catch (error: unknown) {
      const currentProviderKind = walletInfo.value?.name;

      if (currentProviderKind) {
        errorMessage.value = getCancelErrorMessage(error, currentProviderKind);
      }

      showErrorDialog.value = true;

      console.error(error);
    } finally {
      isButtonLoading.value = false;
    }
  };

  return {
    buyToken,
    showInsufficientFundsDialog,
    showErrorDialog,
    showSuccessDialog,
    isButtonLoading,
    countTokensPurchased,
    linkToTransaction,
    errorMessage
  };
};
