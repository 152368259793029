<template>
  <div
    class="transaction-modal-success-container"
    :class="{
      'transaction-modal-success-container-mobile': $device.isMobile
    }"
  >
    <div class="placeholder">
      <img class="lot-image-popup" :src="iconSrc" />
    </div>

    <div class="price-wrap">
      <div class="bought-label">{{ $t('buypackButtonBoughtTokens', { label: $t(token.label) }) }}:</div>
      <div class="price-wrap-right">
        <p v-if="token && tokenReward">
          {{ formatNumber(token.name.contract === Tokens.pearl ? tokenReward : tokenReward * 2, ',') }}
          {{ addSpaces(token.methodName) }}
        </p>
        <p v-else-if="countTokenPurchased && token">{{ countTokenPurchased }} {{ $t(token.label) }}</p>

        <p v-if="extraRewardKey && extraRewards && extraRewards > 0">
          &nbsp; + {{ formatNumber(extraRewards, ',') }} {{ extraRewardKey.toUpperCase() }}
        </p>
      </div>
    </div>

    <div class="price-wrap">
      <div class="spent-label">{{ $t('buypackButtonSpent') }}:</div>
      <div class="price-wrap-right iconed">
        <img src="/img/icons/usdt.svg" loading="lazy" alt="" width="24" />
        <span class="balance-spec">{{ price }} {{ $t('coreUsdt') }}</span>
      </div>
    </div>

    <div v-if="address" class="price-wrap medium">
      <div class="transaction-label">{{ $t('appStatisticTableTransaction') }}:</div>
      <div class="price-wrap-right explorer">
        <a :href="linkToTransaction" target="_blank">
          {{ $t('appStatisticExplorer') }}
          <img class="line-square-icon" src="/public/img/icons/link.svg" alt="Explorer icon" />
        </a>
      </div>
    </div>

    <div class="buttons-wrapper">
      <button class="btn-primary" size="large" @click="$emit('close')">OK</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { Tokens, type TTokensConfigItem } from '../utils/constants';

const { address } = useWeb3ModalAccount();

defineEmits(['close']);

defineProps<{
  iconSrc: string;
  token: TTokensConfigItem;
  linkToTransaction: string;
  countTokenPurchased: number;
  price: number;
  tokenReward?: number;
  extraRewards?: number;
  extraRewardKey?: string;
}>();
</script>

<style scoped lang="scss">
.transaction-modal-success-container {
  .placeholder {
    border-radius: 12px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .lot-image-popup {
    max-width: 100%;
    max-height: 167px;
    margin: 10px 0;
  }

  .price-wrap {
    display: flex;
    color: var(--second-text-color);
    font-size: 18px;
    font-weight: 400;
    margin-top: 16px;
    font-family: 'Eczar', sans-serif;

    .bought-label,
    .spent-label,
    .transaction-label {
      font-family: Eczar;
      font-weight: 500;
      font-size: 20px;
      line-height: 1;

      @media screen and (max-width: 900px) {
        font-size: 16px;
      }
    }
  }

  .price-wrap-right {
    margin-left: auto;
    color: var(--main-text-color);
    text-align: right;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .iconed {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 17px;
    }
  }

  .balance-spec {
    padding-left: 8px;
  }

  .medium {
    text-transform: capitalize;
    font-weight: normal;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    padding-bottom: 8px;
  }

  .explorer {
    a {
      font-weight: 500;
      padding-right: 7px;
      text-transform: none;
      display: flex;
    }

    img {
      width: 16px;
      margin-left: 7px;
    }
  }

  .line-square-icon {
    font-family:
      Line Square Icons,
      sans-serif;
  }

  .buttons-wrapper {
    margin-top: 24px;
    height: 62px;
    display: flex;
    justify-content: center;

    .btn-primary {
      width: 240px;
    }

    .btn-primary:not(:disabled):hover {
      .right-arrow-icon {
        background-image: url('~/public/img/icons/login-arrow-hover.svg');
      }
    }
  }
}

.transaction-modal-success-container-mobile {
  .buttons-wrapper {
    height: 56px;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
</style>

<style lang="scss">
.el-dialog.transaction-modal-success {
  min-width: 300px;
  max-width: 500px;
  border: 2px solid var(--confirm-color);
}
</style>
