<template>
  <el-dialog
    v-model="showSellerModalInjected"
    append-to-body
    class="sellerModal"
    :class="{ 'ru-locale': $i18n.locale === 'ru', sellerModalMobile: $device.isMobile }"
    :center="true"
    destroy-on-close
    :fullscreen="$device.isMobile"
    @closed="$emit('closed')"
    @opened="$emit('opened')"
  >
    <template #header>
      <UILinearTitle>
        <div class="sellerModalTitle">
          <span>{{ $t('seller') }}</span>
          <span class="sellerModalClosest">{{ sellerData?.length ? $t('coreOpen') : $t('coreClosed') }}</span>
        </div>
      </UILinearTitle>

      <SellerModalInfoTooltip />
    </template>

    <template #default>
      <el-dialog
        v-model="showSellerModalConfirm"
        class="sellerModalConfirmBuy"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
        :fullscreen="$device.isMobile"
        :title="$t('coreTokenPurchase')"
        append-to-body
        :center="true"
        destroy-on-close
        @closed="handleCloseModalConfirmBuy"
      >
        <SellerModalConfirmBuy
          v-if="selectedToken"
          :count="countProducts"
          :product="selectedToken"
          @click-buy="handleClickBuy"
          @click-max="handleClickMax"
        />
      </el-dialog>

      <el-dialog
        v-model="showInsufficientFundsDialog"
        :title="$t('buypackButtonInsufficientFunds')"
        :fullscreen="$device.isMobileOrTablet"
        class="insufficient-funds-modal"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
        append-to-body
        destroy-on-close
        align-center
      >
        <InsufficientFundsModal :price="totalPrice" @close="showInsufficientFundsDialog = false" />
      </el-dialog>

      <el-dialog
        v-if="selectedToken"
        v-model="showSuccessDialog"
        :fullscreen="$device.isMobile"
        :title="$t('coreTransactionModalSuccessTitle')"
        append-to-body
        destroy-on-close
        class="transaction-modal-success"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      >
        <TransactionModalSuccess
          :icon-src="
            tokens[tokenMetaData(blockchain.contracts, selectedToken.tokenAddress).name.contract as Tokens].toString()
          "
          :price="totalPrice"
          :count-token-purchased="countTokensPurchased"
          :token="tokenMetaData(blockchain.contracts, selectedToken.tokenAddress)"
          :link-to-transaction="linkToTransaction"
          @close="showSuccessDialog = false"
        />
      </el-dialog>

      <el-dialog
        v-if="selectedToken"
        v-model="showErrorDialog"
        class="transaction-modal-error"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
        :fullscreen="$device.isMobile"
        :title="$t('coreTransactionModalErrorTitle')"
        destroy-on-close
        append-to-body
      >
        <TransactionModalError
          :icon-src="
            tokens[tokenMetaData(blockchain.contracts, selectedToken.tokenAddress).name.contract as Tokens].toString()
          "
          :error-message="errorMessage"
          @close="showErrorDialog = false"
        />
      </el-dialog>

      <div v-if="!isAllSoldOut" class="top-toolbar">
        <div class="balance-wrapper">
          <UIBalance v-if="address" :token-address="blockchain.contracts.usdt" :label="$t('coreBalance')" />
        </div>
        <button v-if="!isAllSoldOut" class="blue-button">{{ $t('coreTokens') }}</button>
      </div>

      <UIBlurWrapper>
        <div class="seller-modal-content">
          <div class="seller-container">
            <video autoplay muted loop playsinline preload="none" width="100%">
              <source :src="`/assets/video/seller.mp4`" type="video/mp4" />
            </video>
            <UICard
              :class="{
                'card-sold-out': isAllSoldOut
              }"
            >
              <span v-if="!isAllSoldOut">{{ $t('sellerMessage') }}</span>
              <p v-else v-html="$t('sellerSoldOut')"></p>
            </UICard>
          </div>

          <el-skeleton :loading="sellerStatus && sellerStatus === 'CONNECTING'" animated class="seller-lots">
            <template #template>
              <el-skeleton-item v-for="(item, index) in new Array(6)" :key="index" class="skeleton-product-card" />
            </template>

            <template #default>
              <div v-if="!isAllSoldOut" class="seller-lots">
                <el-tooltip
                  v-for="(item, index) in sellerData"
                  :key="index"
                  trigger="click"
                  popper-class="popper-default popper-seller"
                  placement="bottom"
                  :auto-close="1000"
                  :offset="-150"
                  :disabled="Boolean(item.amount) || !address"
                >
                  <template #content>
                    <span>{{ $t('sellerNoTokens') }}</span>
                  </template>

                  <UIProductCard
                    :key="index"
                    :icon-src="
                      tokens[tokenMetaData(blockchain.contracts, item.tokenAddress).name.contract as Tokens].toString()
                    "
                    :product-title="
                      $t(tokenMetaData(blockchain.contracts, item.tokenAddress).label as Tokens, { count: 0 })
                    "
                    :product-amount="item.amount"
                    :product-price="item.price"
                    @click="() => handleClick(item)"
                  />
                </el-tooltip>
              </div>

              <div v-else class="sold-out-container">
                <UICard>
                  <p v-html="$t('sellerSoldOut')"></p>
                </UICard>

                <div class="sold-out-img-container">
                  <img src="../../public/img/sellerModal/soldout.png" alt="sold-out-image" />
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
      </UIBlurWrapper>

      <div v-if="$device.isMobile && isAllSoldOut" class="button-wrapper">
        <button class="btn-primary" @click="$emit('closed')">
          <span>{{ $t('coreClose') }}</span>
        </button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { useEnvs } from '#imports';

import { inject } from 'vue';
import { Tokens } from '~/utils/constants';
import tokens from 'public/img/tokens';
import type { SellerItem } from '~/types/seller';
import { ref } from 'vue';
import TransactionModalError from '~/components/TransactionModalError.vue';
import type { WebSocketStatus } from '@vueuse/core';

defineEmits(['closed', 'opened']);

const { login } = useAuth();
const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();
const {
  buyToken,
  isButtonLoading,
  countTokensPurchased,
  showInsufficientFundsDialog,
  showErrorDialog,
  showSuccessDialog,
  linkToTransaction,
  errorMessage
} = await useBuyToken();

const MIN_COUNT = 1;
const showSellerModalConfirm = ref(false);
const selectedToken = ref<SellerItem>();
const countProducts = ref(MIN_COUNT);

const showSellerModalInjected = inject<Ref<boolean>>('showSellerModal');

const sellerData = inject<Ref<SellerItem[]>>('sellerData');
const sellerStatus = inject<Ref<WebSocketStatus>>('sellerStatus');

const totalPrice = computed(() => {
  if (!selectedToken.value) {
    return 0;
  }

  return parseFloat((selectedToken.value.price * countProducts.value).toFixed(5));
});

const isAllSoldOut = computed(() => {
  if (!sellerData?.value) {
    return false;
  }

  return sellerData?.value?.every((item) => item.amount === 0);
});

const handleClick = async (item: SellerItem) => {
  selectedToken.value = item;

  if (!address.value) {
    await login();
    return;
  }

  if (!selectedToken.value?.amount) {
    return;
  }

  showSellerModalConfirm.value = true;
};

const handleClickBuy = async () => {
  if (!selectedToken.value?.tokenAddress || !sellerData?.value) {
    return;
  }

  const tokenIndex = sellerData.value?.findIndex((item) => item.tokenAddress === selectedToken.value?.tokenAddress);

  if (tokenIndex === undefined) {
    return;
  }

  await buyToken(tokenIndex, countProducts.value, totalPrice.value, selectedToken.value.tokenAddress);

  showSellerModalConfirm.value = false;
};

const handleClickMax = () => {
  if (!sellerData?.value) {
    return;
  }

  const token = sellerData.value?.find((item) => item.tokenAddress === selectedToken.value?.tokenAddress);

  if (!token) {
    return;
  }

  countProducts.value = token.amount;
};

const setCountProducts = (value: number) => {
  countProducts.value = value;
};

const handleCloseModalConfirmBuy = () => {
  countProducts.value = MIN_COUNT;
};

provide('countProducts', countProducts);
provide('setCountProducts', setCountProducts);
provide('totalPrice', totalPrice);
provide('buyButtonLoading', isButtonLoading);
</script>
<style scoped lang="scss">
.seller-modal-content {
  display: flex;
  gap: 26px;
  margin: 16px 18px 16px 25px;
  justify-content: center;

  .seller-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 210px;
    min-height: 380px;

    @media screen and (max-width: 900px) {
      display: none;
    }

    .card-default {
      position: absolute;
      bottom: 0;
      font-family: Eczar;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-align: center;
      padding: 16px;
      color: var(--white-70);
    }

    .card-sold-out {
      width: 270px;
    }
  }

  .seller-lots {
    display: grid;
    justify-content: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, minmax(180px, 180px));
    height: 380px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 10px;

    @media screen and (max-width: 340px) {
      grid-template-columns: repeat(1, minmax(180px, 180px));
    }

    @media screen and (min-width: 767px) {
      grid-template-columns: repeat(3, minmax(180px, 180px));
    }
  }

  .blur-wrapper {
    padding: 20px 10px;
  }

  .sold-out-container {
    display: flex;
    overflow: hidden;
    position: relative;

    .sold-out-img-container {
      display: flex;
      justify-content: center;
      min-width: 580px;

      img {
        width: 370px;
        height: 380px;
      }
    }

    .card-default {
      display: none;
      width: 340px;
      padding: 16px;
      text-align: center;

      font-family: Eczar;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.2;
    }

    @media screen and (max-width: 900px) {
      .card-default {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .sold-out-img-container {
        min-width: unset;
      }
    }
  }
}

.top-toolbar {
  display: flex;
  min-height: 48px;
  position: relative;
  margin-bottom: 8px;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  .blue-button {
    font-family: Grenze;
    font-weight: 600;
    font-size: 20px;
    color: var(--main-text-color);
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
    width: 200px;
    height: 48px;
    border-radius: 12px;
    border: transparent;
    position: absolute;
    right: 32px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .balance-wrapper {
    min-width: 280px;
    max-width: 280px;
  }
}

.button-wrapper {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  z-index: 2;

  .btn-primary {
    width: 275px;
  }
}

.skeleton-product-card {
  width: 180px;
  height: 240px;
  min-width: 180px;
  max-height: 240px;
}

.el-dialog.sellerModalMobile {
  overflow: hidden;

  .seller-lots {
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    padding-right: 5px;
    padding-left: 10px;
    height: 75vh;
  }

  .seller-container {
    video {
      position: absolute;
      bottom: 80px;
    }
  }

  .sold-out-container {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-default {
      width: 320px;
    }
  }

  .button-wrapper {
    button {
      height: 56px;
      min-width: 275px;
      display: none;

      @media screen and (max-width: 900px) {
        display: block;
      }
    }
  }

  .seller-modal-content {
    margin: 8px 0 16px;
  }
}
</style>

<style lang="scss">
.el-popper.popper-default.is-dark.popper-seller {
  max-width: 220px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.1;
  text-align: center;
}

.el-dialog.sellerModal {
  margin: 0;
  position: fixed;
  width: 100%;
  min-width: 350px;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('~/public/img/sellerModal/seller-modal-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 8px 16px 16px;

  @media screen and (max-width: 479px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 767px) {
    max-width: 900px;
    min-height: 600px;
  }

  .el-dialog__body {
    min-width: 100%;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 0;
  }

  &:after {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 27, 37, 0.4);
  }

  .card-default {
    p {
      color: var(--main-text-color);
    }

    p > span {
      color: var(--blue_highlight);
    }
  }

  .sellerModalTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: nowrap;

    .sellerModalClosest {
      font-family: Eczar;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.1;
      color: var(--blue_highlight);
    }
  }

  .top-toolbar .balance .price-container img {
    width: 21px;
  }

  .blur-wrapper {
    overflow: hidden;

    @media screen and (min-width: 900px) {
      background: #021d2b;
    }
  }

  .el-overlay-dialog {
    overflow: hidden;
  }
}

.el-dialog.sellerModalMobile {
  max-width: unset;
  min-width: unset;
  padding: 0 4px;
  overflow: hidden;

  @media screen and (max-width: 375px) {
    padding-left: 2px;
    padding-right: 2px;
  }

  .el-dialog__header.show-close {
    padding-top: 0;
  }

  .el-dialog__body {
    padding-top: 8px;
  }

  .blur-wrapper {
    background: transparent;
    backdrop-filter: unset;
    border: unset;
    max-height: unset;

    @media screen and (min-width: 900px) {
      background: #021d2b;
    }
  }
}
</style>
