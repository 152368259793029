<template>
  <div
    class="seller-modal-confirm-buy"
    :class="{
      'seller-modal-confirm-buy-mobile': $device.isMobile
    }"
  >
    <div v-if="address" class="balances-container">
      <UIBalance
        v-if="address"
        :token-address="blockchain.contracts.usdt"
        :label="$t('coreBalance')"
        class="balance-user"
      />
      <UIBalance v-if="product" :token-address="product.tokenAddress" is-format-balance class="balance-token">
        <template #prefix>
          <div class="wallet-icon">
            <img src="~/public/img/wallet.svg" alt="" />
            <hr />
          </div>
        </template>
      </UIBalance>
    </div>

    <div class="seller-modal-confirm-buy-token-image">
      <img
        :src="tokens[tokenMetaData(blockchain.contracts, product.tokenAddress).name.contract as Tokens].toString()"
        alt=""
      />
    </div>

    <section>
      <div class="seller-modal-confirm-buy-available">
        <span class="seller-modal-confirm-buy-available-title">{{ $t('availableForBuySellerModal') }}:</span>
        <span class="seller-modal-confirm-buy-available-highlighted">{{ product.amount }}</span>
      </div>

      <div class="seller-modal-confirm-buy-price-container">
        <span>{{ $t('corePrice') }}:</span>
        <span>
          <UIPrice :value="product.price" :label="`/ 1 ${$t('coreToken')}`" />
        </span>
      </div>
    </section>

    <hr />

    <section class="price-calculator">
      <div class="seller-modal-confirm-total-price">
        <UIPrice :value="totalPriceInjected" :label="$t('coreUsdt')" />
        <span>=</span>
      </div>

      <div class="input-number-container">
        <UIInputNumberWrapper>
          <el-input-number
            v-model="countProductsInjected"
            :step="1"
            :step-strictly="true"
            :validate-event="false"
            :min="1"
            :max="product.amount || 1"
            :value-on-clear="maxCountProductsInjected || 1"
            :disabled="buyButtonLoadingInjected"
          />
          <button
            class="btn-outline btn-max"
            type="button"
            :disabled="!address || buyButtonLoadingInjected"
            @click="$emit('click-max')"
          >
            {{ $t('coreMax') }} ({{ product.amount }})
          </button>
        </UIInputNumberWrapper>
      </div>
    </section>

    <div class="button-wrapper">
      <button class="btn-primary" :disabled="!product.amount || buyButtonLoadingInjected" @click="$emit('click-buy')">
        <span>{{ $t('coreBuy') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SellerItem } from '~/types/seller';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { inject } from 'vue';
import tokens from 'public/img/tokens';
import { Tokens } from '~/utils/constants';
import { useEnvs } from '#imports';

const { address } = useWeb3ModalAccount();
const { blockchain } = useEnvs();

const countProductsInjected = inject<number>('countProducts');
const maxCountProductsInjected = inject<Ref<number>>('maxCountProducts');
const totalPriceInjected = inject<Ref<number>>('totalPrice');
const buyButtonLoadingInjected = inject<Ref<boolean>>('buyButtonLoading');

defineProps<{
  product: SellerItem;
}>();

defineEmits(['click-buy', 'click-max']);
</script>

<style lang="scss" scoped>
.seller-modal-confirm-buy {
  display: flex;
  flex-direction: column;

  .seller-modal-confirm-buy-token-image {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 8px;

    img {
      height: 111px;
      min-height: 111px;
      min-width: 111px;
      width: 111px;
    }
  }

  hr {
    width: 80%;
    height: 1px;
    border: none;
    outline: none;
    margin: 16px auto;
    background-color: var(--blue-4);
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .price-calculator {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.balances-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-width: 280px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  .balance {
    flex-grow: 1;
  }

  .balance-token {
    flex-grow: 0.2;

    .wallet-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 24px;
      }

      hr {
        margin: 0 0 0 10px;
        width: 14px;
        transform: rotate(90deg);
      }
    }
  }
}

.seller-modal-confirm-buy-available {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .seller-modal-confirm-buy-available-title {
    font-family: Eczar;
    font-weight: 500;
    font-size: 20px;
    color: var(--second-text-color);

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  .seller-modal-confirm-buy-available-highlighted {
    font-family: Eczar;
    font-weight: 600;
    font-size: 20px;
    color: var(--blue_highlight);
  }
}

.seller-modal-confirm-buy-price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  span {
    font-family: Eczar;
    font-weight: 500;
    font-size: 20px;
    color: var(--second-text-color);
  }

  .price {
    span {
      font-family: Eczar;
      font-weight: 500;
      font-size: 20px;
      color: var(--second-text-color);
    }
  }

  &::v-deep(.price-label) {
    text-transform: lowercase;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 62px;

  button {
    font-family: Eczar;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;

    width: 220px;
    max-width: 220px;
  }
}

.seller-modal-confirm-total-price {
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 18px;
    width: 18px;
    max-width: 18px;
    max-height: 18px;

    font-family: Eczar;
    font-weight: 700;
    font-size: 32px;
    color: var(--main-text-color);
  }
}

.seller-modal-confirm-total-price::v-deep(.price-label),
.seller-modal-confirm-total-price::v-deep(.price-value) {
  font-family: Eczar;
  font-weight: 600;
  font-size: 24px;
  color: var(--main-text-color);
}

.input-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-number-wrapper::v-deep(.btn-max) {
  border-radius: 12px;
  font-family: Eczar;
  font-weight: 600;
  font-size: 18px;
  padding: 6.5px 10px;
}

.input-number-wrapper::v-deep(.el-input__inner) {
  font-family: Eczar;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
}

@media screen and (max-width: 479px) {
  .seller-modal-confirm-buy {
    .price-calculator {
      gap: 18px;
    }

    .input-number-wrapper {
      gap: 12px;
    }
  }

  .balances-container {
    gap: 8px;

    .balance {
      height: 40px;
    }
  }

  .seller-modal-confirm-buy section {
    gap: 14px;
  }
}

.seller-modal-confirm-buy-mobile {
  hr {
    margin-top: 18px;
    margin-bottom: 30px;
  }

  .button-wrapper {
    margin-top: 38px;
  }
}
</style>

<style lang="scss">
.sellerModalConfirmBuy {
  max-width: 530px;
  min-width: 360px;

  .el-dialog__body {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .balance {
    font-size: 20px;

    .price-container {
      img {
        width: 22px;
        height: 18px;
      }
    }
  }

  .balance-user .price-value-zero {
    color: var(--error);
  }

  .balance-token .price-container {
    img {
      width: 32px;
      height: 32px;
    }
  }

  .seller-modal-confirm-buy-price-container {
    .price-container img {
      width: 20px;
    }

    .price-content {
      align-items: center;

      .price-value {
        color: var(--main-text-color);
        font-size: 24px;
        font-weight: 600;
      }

      .price-label {
        font-weight: 600;
      }
    }
  }

  .seller-modal-confirm-total-price {
    .price-container img {
      width: 30px;
    }
  }

  @media screen and (max-width: 479px) {
    .el-dialog__body {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
</style>
