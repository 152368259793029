import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

export default async (tokenAddress: Ref<string | string>) => {
  const { address } = useWeb3ModalAccount();
  const { availableTokens } = useTokensReader();
  const balance = ref<string>();
  const isLoading = ref(false);

  const pullBalance = async (address: string, tokenAddress: string) => {
    try {
      isLoading.value = true;

      const balanceToken = await availableTokens(address, tokenAddress);
      balance.value = Number(balanceToken).toFixed(2);
    } finally {
      isLoading.value = false;
    }
  };

  watch(
    [address, tokenAddress],
    async ([addressValue, tokenAddressValue]) => {
      if (!addressValue || !tokenAddress) return;

      await pullBalance(addressValue, tokenAddressValue);
    },
    {
      immediate: true
    }
  );

  return {
    balance,
    isLoading,
    pullBalance
  };
};
