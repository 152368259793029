<template>
  <div class="linear-title" :class="{ 'ru-locale': $i18n.locale === 'ru' }">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
.linear-title {
  display: flex;
  flex-direction: column;
  font-family: Grenze;
  font-weight: 700;
  font-size: 32px;
  width: 80%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  border-top: 0.5px solid rgba(0, 131, 173, 0.3);
  border-bottom: 0.5px solid rgba(0, 131, 173, 0.3);
  background: linear-gradient(90deg, rgba(8, 27, 37, 0) 0%, rgba(8, 27, 37, 0.5) 50%, rgba(8, 27, 37, 0) 100%);

  @media screen and (max-width: 479px) {
    font-size: 30px;
  }
}
</style>
